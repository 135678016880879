<template>

<b-container>

 <b-alert v-if="appState!='loading'"
            :show="alertComputed.alertVisible"
            :dismissible="alert.dismissible"
            :variant="alert.variant"
            @dismissed="hideAlert"
            @dismiss-count-down="countDownChanged">
        <p class="preserve-lb">{{alert.alertText}}</p>
        <b-progress v-show="alertComputed.alertProgressVisible"
                  :variant="alert.variant"
                  :max="alert.dismissSecs"
                  :value="alert.dismissCountDown"
                  height="4px">
        </b-progress>
    </b-alert>

  <icon v-if="appState=='loading'" name="spinner" pulse scale="4"/> 
  <div v-else-if="appState=='error'">Fehler beim Lesen der Daten.</div> 
  <div v-else-if="appState=='ready'"> 
  <b-row class="my-1 gray-border">
    <b-col>
   
          <h4>Spiel</h4> 

          <icon v-if="match.away_yn" name="road"/> 
          <icon v-else name="home"/> 
          <br>
          {{ match.homeTeam.full_name }} -
          {{ match.awayTeam.full_name }} <br>          
     
    </b-col>
  </b-row>
            <b-row class="my-1 gray-border">
    <b-col>
          <h4>Termin Alt</h4> 
          {{ "" + match.matchDay + "."  + match.matchMonth + "." + match.matchYear}}
          {{ " (" + match.matchWeekDay + ") " }}
          {{ match.matchHours + ':' + ((match.matchMinutes >= 10) ? match.matchMinutes : '0' + match.matchMinutes) + " Uhr" }}
     
    </b-col>
  </b-row>
          
  <b-row class="my-1 gray-border">
    <b-col>
          <h4>Termin Neu</h4>
            <b-row class="my-1">
              <b-col sm="2"><label for="type-date">Datum neu:</label></b-col>
              <b-col sm="5"><b-form-input id="type-date" type="date" class="ios-date-bug" size="sm" v-model="newMatchDateModel"></b-form-input></b-col>
              <b-col sm="1"><label for="type-time">Zeit neu:</label></b-col>
              <b-col sm="4"><b-form-input id="type-time" type="time" class="ios-date-bug" size="sm" v-model="newMatchTimeModel"></b-form-input></b-col>
            </b-row>
            <b-row class="my-1">
              <b-col> 
                <b-form-checkbox
                     v-model="newMatchSwitchHomeAway"
                >
                Tausch Heimrecht
              </b-form-checkbox>  
              </b-col>
            </b-row>      
    </b-col>
  </b-row>
  <b-row>
    <b-col>
      <b-input-group>
            <b-button size="sm" @click="goBack"><span><icon name="arrow-left"/> Cancel</span></b-button>
            <b-button size="sm" @click="save">Save</b-button>
            <b-button size="sm" @click="test">Test</b-button>
      </b-input-group>
    </b-col>
  </b-row>

  </div>
</b-container>
  
</template>

<script>
import DBAPI from '../db/dbapi'
import Utils, { s2i, makeCopy } from '../helper/Utils'
import MessageAlertMixin from '../mixins/MessageAlertMixin'

export default {
  name: 'AdjournMatch',
  mixins:[MessageAlertMixin],
  mounted() {
    this.processQuery()
    this.getData()
  },
  watch: {
    	'$route': function(newRoute, oldRoute) {
        this.processQuery()
      },
  },
  data () {
    return {
      matchId: null,
      matches: [],
      teams: { },
      newMatchDateModel:null,
      newMatchTimeModel:null,
      newMatchSwitchHomeAway:false,
      appState:'loading' 
    }
  },
  computed: {
    fieldsComputed() {
      var retVal = []
      return retVal
    },
    match() {
      return this.matchComputed
    },
    matchComputed() {
      var self = this
      var myClubNr = this.poll.club_nr
      var myTeamNr = this.poll.team_nr
      var match = self.matches.find(m => m._id === self.matchId)
      if (!match) {
        return null
      }
      var m = makeCopy(match)
      
      m.away_yn = (m.away_club_nr == myClubNr && m.away_team_nr == myTeamNr)  // adds new attribute to copied match
      // m.oppTeamId = (m.away_club_nr == myClubNr) ? m.home_club_nr : m.away_club_nr; // adds new attribute to copied match
      var matchDate = new Date(m.date)

      m.matchDate = matchDate
      m.matchYear = matchDate.getFullYear() 
      m.matchMonth = matchDate.getMonth() + 1
      m.matchDay = matchDate.getDate() 
      m.matchHours = matchDate.getHours() 
      m.matchMinutes = matchDate.getMinutes() 
      let DAYS = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
      m.matchWeekDay = DAYS[matchDate.getDay()]
      // m.opponentTeam = self.teams[m.oppTeamId]
      m.homeTeam = self.teams[m.home_club_nr]
      m.awayTeam = self.teams[m.away_club_nr]
      return m
    }
  },
  methods: {
    processQuery() {
      // console.log("$route.query", JSON.stringify(this.$route.query))
      if (this.$route.query) {
        this.matchId = this.$route.query.matchId
        this.pollId = this.$route.query.pollId
      }
    
    },
    getData: function() {
      this.appState = 'loading'
      var self = this
      
      DBAPI.getPlayerSchedules({pollId:this.pollId})
      .then(function(response) {
        // console.log("response.data", response.data.playerSchedules);
        if (response.data.error){
          console.error("error in getPlayerSchedules", response.data.message)

          self.appState = 'error'
          self.showAlert("Konnte Spielerdaten nicht lesen.")

        }	else {
          if (!response.data.poll._id) {
            // no poll found --> show acitve polls
            self.$router.push('/polls')
            return
          }

          self.poll = response.data.poll
          var teams   = {}

          response.data.teams.forEach(t => {
              teams[t.club_nr] = t;
            })
          self.teams = teams
          self.matches = response.data.poll.matches || []

          var match = self.matches.find(m => m._id === self.matchId)
          setTimeout(function(){  // workaround iOS bug with input type="date" / "time"
            var matchDate = new Date(match.date)
            var month = matchDate.getMonth() + 1
            month = month < 10 ? "0"+month : ""+month
            var day = matchDate.getDate()
            day = day < 10 ? "0"+day : ""+day
            var hours = matchDate.getHours()
            hours = hours < 10 ? "0"+hours : ""+hours
            var minutes = matchDate.getMinutes()
            minutes = minutes < 10 ? "0"+minutes : ""+minutes


            self.newMatchDateModel = matchDate.getFullYear() + "-" + month + "-" + day  //match.date.substring(0,10)
            self.newMatchTimeModel = hours + ":" + minutes // match.date.substring(11,16)
            }, 250)

          self.appState = 'ready'

        }       
    })
    .catch( function(error) {
        console.error("error in getPlayerSchedules (catch)", error)

        self.appState = 'error'
        self.showAlert("Konnte Spieldaten nicht lesen.")
      });
      
    },
    goBack: function() {
      this.$router.go(-1);
    },
    test: function() {
      console.log("newMatchDateModel", this.newMatchDateModel)
      console.log("newMatchTimeModel", this.newMatchTimeModel)
      console.log("newMatchSwitchHomeAway", this.newMatchSwitchHomeAway)
    },
    save: function() {
      let self = this
      var origMatch = self.matches.find(m => m._id === self.matchId)//makeCopy(self.matches.find(m => m._id === self.matchId))
      if (!origMatch) {
        console.error("error: AdjournMatch.save: match not set")
        return 
      }
      
      origMatch.basis_id = origMatch._id
      var newMatch = makeCopy(origMatch)
      delete newMatch['_id']
      newMatch.state = 2
      // var newDate = new Date(this.newMatchDateModel + "T" + this.newMatchTimeModel + ":00.000Z")
      var newDate = new Date(this.newMatchDateModel + " " + this.newMatchTimeModel + ":00")
      // console.log(newDate, newDate.getHours(), newDate.toISOString(), newDate.toUTCString(), origMatch.date)
//       console.log(new Date(origMatch.date), new Date(origMatch.date).getHours())

      newMatch.date = newDate.toISOString()
      
      if (!this.newMatchSwitchHomeAway && origMatch.date===newMatch.date) {
        this.showAlert("Es wurde nichts geändert.", {variant:'info', dismissSecs:3});
        return
      }
      
      if (this.newMatchSwitchHomeAway) {
        newMatch.home_club_nr = origMatch.away_club_nr
        newMatch.home_team_nr = origMatch.away_team_nr
        newMatch.away_club_nr = origMatch.home_club_nr
        newMatch.away_team_nr = origMatch.home_team_nr
      }
      newMatch.basis_id = origMatch.basis_id === null ? origMatch._id : origMatch.basis_id
      
      origMatch.state = 100 + origMatch.state // increase state with 100
      var updatedMatches = []
      var insertMatches = []
      // updatedMatches.push(origMatch)
      // insertMatches.push(newMatch)
      
      
      this.poll.matches.push(newMatch)
      this.poll.matches.sort(function(a,b){
        return new Date(a.date) - new Date(b.date);
      });
      
      
      // var dataArr = {origin: "AdjournMatch",
      //       dbactions: [{dbname: "matches", action: "update", keys:["id"], inputData: updatedMatches},
      //                   {dbname: "matches", action: "insert", keys:[], inputData: insertMatches}]
      //   }
      // var data = JSON.stringify(dataArr)
      var data = { }
      data.pollId = this.poll._id
      data.matches = this.poll.matches
      console.log(data)
      
      require('axios').put('api/updatematches', data).then(function(response) {
          console.log("response.data", response.data);
          if (response.data.error || response.data.errors){
            console.error("error DBAPI.putUpsert:", response.data.message)
					}	else {
            self.goBack()
          }       
      }).catch(e => console.error("putUpsert catch: ", e));
      return
      DBAPI.putUpsert(data).then(function(response) {
          // console.log("response.data", response.data.playerSchedules);
          if (response.data.error || response.data.error){
            console.error("error DBAPI.putUpsert:")
            console.error(response.data.message)
					}	else {
            self.goBack()
          }       
      }).catch(e => console.error("putUpsert catch: ", e));
      
    }
  }
}

</script>

<style>
.bilsum {  
    font-weight: bold;
}
.ios-date-bug {
  min-height: 2.1em;
}
.gray-border { 
  border: 1px solid;
  border-radius: 5px;
  border-color: gray;
}
</style>
